import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'

import {
  SEND_MAIL,
  UPDATE_GOLF_BOOKING,
} from '../../../../store/application/actionType'

const CancelModal = ({ data, onCloseHandler }) => {
  const dispatch = useDispatch()

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialObj = {
    remarks: '',
  }
  const [state, setState] = useState(initialObj)

  const [modal_varying1, setmodal_varying1] = useState(true)
  const [modalData, setModalData] = useState(null)

  /* ---------------------------- REDUX STATES ---------------------------- */
  const {
    golfBookingStatusData,
    golfBookingStatusLoading,
    golfBookingStatusError,
  } = useSelector((state) => ({
    golfBookingStatusData: state.UpdateGolfBooking.data,
    golfBookingStatusLoading: state.UpdateGolfBooking.loading,
    golfBookingStatusError: state.UpdateGolfBooking.error,
  }))

  useEffect(() => {
    if (golfBookingStatusData?.code === 111) {
      onCloseHandler(false)
      setmodal_varying1(!modal_varying1)
    } else if (golfBookingStatusData !== null) {
      onCloseHandler(false)
      setmodal_varying1(!modal_varying1)
    }
  }, [golfBookingStatusData])

  useEffect(() => {
    if (data !== null) {
      setModalData(data)
    }
  }, [data])

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = (e) => {
    const { name, value } = e.target

    switch (name) {
      case 'cancellation remarks':
        setState({ ...state, remarks: value })
        break

      default:
      // do nothing
    }
  }

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    // do an api call
    dispatch({
      type: UPDATE_GOLF_BOOKING,
      payload: {
        bookingId: data.objectId,
        payloadData: {
          status: 'CANCELLED BY THRIWE',
          remarks: {
            // customer: "Booking on with Amy mcrobs tee time",
            // admin_recommended: "",
            // admin_confirmed: "",
            admin_cancellation: state.remarks,
          },
        },
      },
    })
    const mailPayload = {
      userId: data?.user?.id,
      bookingId: data?.objectId,
      templateCode: 'email_07',
      emailId: data?.user?.email,
      cancellationReason: state.remarks,
    }

    dispatch({
      type: SEND_MAIL,
      payload: {
        payloadData: mailPayload,
      },
    })
  }

  const tog_varying1 = () => {
    onCloseHandler(false)
    setmodal_varying1(!modal_varying1)
  }

  return (
    <Modal
      isOpen={modal_varying1}
      toggle={() => {
        tog_varying1()
      }}
      id="exampleModal"
    >
      <ModalHeader
        toggle={() => {
          tog_varying1()
        }}
      >
        Cancel Remarks
      </ModalHeader>
      <ModalBody>
        <form>
          <div className="mb-3">
            <select
              className="form-select"
              name="cancellation remarks"
              value={state.remarks}
              onChange={inputChangeHandler}
            >
              <option value="">Please select a reason for cancellation</option>
              <option value="Requested Slot Not Available">
                Requested Slot Not Available
              </option>
              <option value="Tournament at the golf course">
                Tournament at the golf course
              </option>
              <option value="Golf Course Closed">Golf Course Closed</option>
              <option value="Golf Course Fully Booked">
                Golf Course Fully Booked
              </option>
              <option value="No response on recommendation">
                No response on recommendation
              </option>
            </select>
          </div>
        </form>
      </ModalBody>
      <div className="modal-footer d-flex justify-content-center">
        <Button
          className="overlay-disable"
          color="primary"
          onClick={submitHandler}
          disabled={state.remarks === ''}
        >
          Submit
        </Button>
      </div>
    </Modal>
  )
}

export default CancelModal
