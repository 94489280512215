import { takeEvery, fork, put, all, call } from 'redux-saga/effects'

import fetch from './../../fetch'
import { baseURL, commonHeaders } from '../../../../Containers/Confiq/index.js'
import {
  DOWNLOAD_DUMP,
  DOWNLOAD_DUMP_SUCCESS,
  DOWNLOAD_DUMP_ERROR,
} from './../../actionType.js'

function* DownloadDump({ payload }) {
  try {
    const response = yield call(fetch, {
      url: `${baseURL}/parse/classes/golfcourses/`,
      method: 'GET',
      headers: { ...commonHeaders },
    })

    if (response?.error === undefined) {
      yield put({ type: DOWNLOAD_DUMP_SUCCESS, payload: response })
    } else {
      yield put({ type: DOWNLOAD_DUMP_ERROR, payload: response.error })
    }
  } catch (error) {
    yield put({ type: DOWNLOAD_DUMP_ERROR, payload: error })
  }
}

export function* watchDownloadDump() {
  yield takeEvery(DOWNLOAD_DUMP, DownloadDump)
}

function* downloadDumpSaga() {
  yield all([fork(watchDownloadDump)])
}

export default downloadDumpSaga
