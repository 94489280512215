import {
  UPDATE_GOLF_BOOKING,
  UPDATE_GOLF_BOOKING_SUCCESS,
  UPDATE_GOLF_BOOKING_ERROR,
  EMPTY_UPDATE_GOLF_BOOKING,
} from './../../actionType.js'

const initialState = {
  data: null,
  message: null,
  loading: false,
  success: false,
  error: false,
}

const UpdateGolfBooking = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_GOLF_BOOKING:
      state = {
        ...state,
        data: null,
        loading: true,
        success: false,
        error: false,
        message: null,
      }
      break

    case UPDATE_GOLF_BOOKING_SUCCESS:
      state = {
        ...state,
        data: action.payload,
        loading: false,
        success: true,
        error: false,
        message: null,
      }
      break

    case UPDATE_GOLF_BOOKING_ERROR:
      state = {
        ...state,
        data: null,
        loading: false,
        success: false,
        error: true,
        message: action.payload,
      }
      break

    case EMPTY_UPDATE_GOLF_BOOKING:
      state = {
        data: null,
        message: null,
        loading: false,
        success: false,
        error: false,
      }
      break

    default:
      state = { ...state }
      break
  }
  return state
}

export default UpdateGolfBooking
