import { all, fork } from 'redux-saga/effects'
//layout
import LayoutSaga from './layouts/saga'

//Auth
import AccountSaga from './auth/register/saga'
import AuthSaga from './auth/login/saga'
import ForgetSaga from './auth/forgetpwd/saga'
import ProfileSaga from './auth/profile/saga'

//calendar
import calendarSaga from './calendar/saga'

//chat
import chatSaga from './chat/saga'

//ecommerce
import ecommerceSaga from './ecommerce/saga'

//Project
import projectSaga from './projects/saga'

// Task
import taskSaga from './tasks/saga'

// Crypto
import cryptoSaga from './crypto/saga'

//TicketsList
import ticketsSaga from './tickets/saga'

//crm
import crmSaga from './crm/saga'

//invoice
import invoiceSaga from './invoice/saga'

//mailbox
import mailboxSaga from './mailbox/saga'

/* ---------------------------- AFTER CUSTOMIZATION ---------------------------- */

// User Login
import userLoginSaga from './application/common/login/saga'

// User Logout
import userLogoutSaga from './application/common/logout/saga'

// Mail
import sendMailSaga from './application/common/mail/saga'

// All Projects
import allProjectsSaga from './application/common/projects/saga'

// All Golf Courses
import allGolfCoursesSaga from './application/common/golfcourses/saga'

// Download Dump
import DownloadDump from './application/common/downloadDump/saga'

// Golf Booking
import golfBookingsSaga from './application/golf/golfbooking/saga'
import updateGolfBookingSaga from './application/golf/updategolfbooking/saga'
import getGolfBookingById from './application/golf/getGolfBookingById/saga'

export default function* rootSaga() {
  yield all([
    //public
    fork(LayoutSaga),
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(chatSaga),
    fork(projectSaga),
    fork(taskSaga),
    fork(cryptoSaga),
    fork(ticketsSaga),
    fork(calendarSaga),
    fork(ecommerceSaga),
    fork(crmSaga),
    fork(invoiceSaga),
    fork(mailboxSaga),

    /* ---------------------------- AFTER CUSTOMIZATION ---------------------------- */

    fork(allProjectsSaga),
    fork(allGolfCoursesSaga),
    fork(userLoginSaga),
    fork(golfBookingsSaga),
    fork(updateGolfBookingSaga),
    fork(userLogoutSaga),
    fork(sendMailSaga),
    fork(getGolfBookingById),
    fork(DownloadDump),
  ])
}
