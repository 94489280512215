import React, { useEffect, useRef } from 'react'

import { CSVLink, CSVDownload } from 'react-csv'

const DownloadDump = ({ data }) => {
  return (
    <>
      <CSVDownload data={data} target="_blank" />
    </>
  )
}

export default DownloadDump
