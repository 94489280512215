import React, { useEffect, useState } from 'react'

import moment from 'moment'
import { Card, CardBody, Col, Row, Container, Label } from 'reactstrap'

const BookingDetails = ({ details }) => {
  const [bookingDetails, setBookingDetails] = useState([])

  useEffect(() => {
    setBookingDetails(details)
  }, [details])

  return (
    <Container fluid>
      <Row>
        <Col lg={12}>
          <Card className="mt-n4 mx-n4 mb-n5">
            <div className="bg-soft-warning">
              <CardBody className="pb-4 mb-5">
                <Row>
                  <div className="col-md">
                    <Row className="align-items-center">
                      <div className="col-md-auto mb-1 mt-4"></div>
                      <div className="col-md">
                        <h4 className="fw-semibold" id="ticket-title">
                          {details.objectId || ''}
                        </h4>
                      </div>
                    </Row>
                  </div>
                </Row>
              </CardBody>
            </div>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">User Details</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Order Id'}</Label>
                    <Label className="text-muted">
                      {details?.order_id || `NA`}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'First Name'}</Label>
                    <Label className="text-muted">
                      {details?.user?.first_name || `NA`}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Last Name'}</Label>
                    <Label className="text-muted">
                      {details?.user?.last_name || `NA`}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Country Code'}</Label>
                    <Label className="text-muted">
                      {details?.user?.country_code || `NA`}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Email'}</Label>
                    <Label className="text-muted">
                      {details?.user?.email || `NA`}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Phone'}</Label>
                    <Label className="text-muted">
                      {details?.user?.phone || `NA`}
                    </Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">
                Booking Details
              </h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Booking Id'}</Label>
                    <Label className="text-muted">
                      {details?.objectId || `NA`}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Status'}</Label>
                    <Label className="text-muted">
                      {details?.status || `NA`}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Created At'}</Label>
                    <Label className="text-muted">
                      {details?.createdAt
                        ? moment(`${details?.createdAt}`).format(
                            'DD/MM/YYYY, h:mm:ss a',
                          )
                        : `NA`}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Updated At'}</Label>
                    <Label className="text-muted">
                      {details?.status === 'PENDING'
                        ? details?.createdAt
                          ? moment(`${details?.createdAt}`).format(
                              'DD/MM/YYYY, h:mm:ss a',
                            )
                          : `NA`
                        : details?.updatedAt
                        ? moment(`${details?.updatedAt}`).format(
                            'DD/MM/YYYY, h:mm:ss a',
                          )
                        : `NA`}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Confirmation Type'}</Label>
                    <Label className="text-muted">
                      {details?.confirmation_type || `NA`}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">
                      {'Is Short Notice Cancellation'}
                    </Label>
                    <Label className="text-muted">
                      {details?.IsShortNoticeCancellation || `NA`}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Handicap'}</Label>
                    <Label className="text-muted">
                      {details?.handicap || `NA`}
                    </Label>
                  </div>
                </Col>
                {/* <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Has Refund'}</Label>
                    <Label className="text-muted">
                      {`${details?.has_refund}`}
                    </Label>
                  </div>
                </Col> */}

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Redemption Type'}</Label>
                    <Label className="text-muted">
                      {details?.redemption_type || `NA`}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Reference Number'}</Label>
                    <Label className="text-muted">
                      {details?.reference_number || `NA`}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Source'}</Label>
                    <Label className="text-muted">
                      {details?.source || `NA`}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Date Of Play Confirmed'}</Label>
                    <Label className="text-muted">
                      {details?.date_of_play?.confirmed || `NA`}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Date Of Play Preferred'}</Label>
                    <Label className="text-muted">
                      {details?.date_of_play?.preferred
                        ? moment(`${details?.date_of_play?.preferred}`).format(
                            'DD/MM/YYYY',
                          )
                        : 'NA'}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">
                      {'Date Of Play Recommended'}
                    </Label>
                    <Label className="text-muted">
                      {details?.date_of_play?.recommended || `NA`}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">
                      {'Golf Courses Confirmed Id'}
                    </Label>
                    <Label className="text-muted">
                      {details?.golf_course?.confirmed?.id || `NA`}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">
                      {'Golf Courses Confirmed Name'}
                    </Label>
                    <Label className="text-muted">
                      {details?.golf_course?.confirmed?.name || `NA`}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">
                      {'Golf Courses Confirmed Type'}
                    </Label>
                    <Label className="text-muted">
                      {details?.golf_course?.confirmed?.type || `NA`}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Redemption Type'}</Label>
                    <Label className="text-muted">
                      {details?.payment?.redemption_type || `NA`}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Reference Number'}</Label>
                    <Label className="text-muted">
                      {details?.payment?.reference_number || `NA`}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Refund Amount'}</Label>
                    <Label className="text-muted">
                      {details?.refund?.amount || `NA`}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Refund By'}</Label>
                    <Label className="text-muted">
                      {details?.refund?.by || `NA`}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Refund Currency'}</Label>
                    <Label className="text-muted">
                      {details?.refund?.currency || `NA`}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Refund Date'}</Label>
                    <Label className="text-muted">
                      {details?.refund?.date || `NA`}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">
                      {'Refund Reference Number'}
                    </Label>
                    <Label className="text-muted">
                      {details?.refund?.reference_number || `NA`}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Refund Status'}</Label>
                    <Label className="text-muted">
                      {details?.refund?.status || `NA`}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Time Of Play Confirmed'}</Label>
                    <Label className="text-muted">
                      {details?.time_of_play?.confirmed || `NA`}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Time Of Play Preferred'}</Label>
                    <Label className="text-muted">
                      {details?.time_of_play?.preferred
                        ? moment(
                            `${details?.time_of_play?.preferred}`,
                            'HHmmss',
                          ).format('h:mm:ss a')
                        : 'NA'}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">
                      {'Time Of Play Recommended'}
                    </Label>
                    <Label className="text-muted">
                      {details?.time_of_play?.recommended || `NA`}
                    </Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">
                Payment Details
              </h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'User ID'}</Label>
                    <Label className="text-muted">
                      {details?.user?.id || `NA`}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Status'}</Label>
                    <Label className="text-muted">
                      {details?.payment?.status || `NA`}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Payment Id'}</Label>
                    <Label className="text-muted">
                      {details?.payment?.id || `NA`}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Method'}</Label>
                    <Label className="text-muted">
                      {details?.payment?.method || `NA`}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">
                      {'Green Fees Final Amount'}
                    </Label>
                    <Label className="text-muted">
                      {details?.payment?.green_fees?.final?.amount || `NA`}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">
                      {'Green Fees Final Currency'}
                    </Label>
                    <Label className="text-muted">
                      {details?.payment?.green_fees?.final?.currency || `NA`}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Discount Percentage '}</Label>
                    <Label className="text-muted">
                      {details?.payment?.discount?.final?.percentage || `NA`}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">
                      {'Discount Percentage Final Currency'}
                    </Label>
                    <Label className="text-muted">
                      {details?.payment?.discount?.final?.currency || `NA`}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">
                      {'Discount Percentage Final Currency'}
                    </Label>
                    <Label className="text-muted">
                      {details?.payment?.discount?.final?.currency || `NA`}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">
                      {'Processing Fees Final Amount'}
                    </Label>
                    <Label className="text-muted">
                      {details?.payment?.processing_fees?.final?.amount || `NA`}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">
                      {'Processing Fees Final Currency'}
                    </Label>
                    <Label className="text-muted">
                      {details?.payment?.processing_fees?.final?.currency ||
                        `NA`}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Discounted Amount'}</Label>
                    <Label className="text-muted">
                      {details?.payment?.sub_total?.final?.amount || `NA`}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Discounted Currency'}</Label>
                    <Label className="text-muted">
                      {details?.payment?.sub_total?.final?.currency || `NA`}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">
                      {'Total Payable Final Amount'}
                    </Label>
                    <Label className="text-muted">
                      {details?.payment?.total_payable?.final?.amount || `NA`}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">
                      {'Total Payable Final Currency'}
                    </Label>
                    <Label className="text-muted">
                      {details?.payment?.total_payable?.final?.currency || `NA`}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Booking Fees Amount'}</Label>
                    <Label className="text-muted">
                      {details?.payment?.booking_fees?.amount || `NA`}
                    </Label>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Booking Fees Currency'}</Label>
                    <Label className="text-muted">
                      {details?.payment?.booking_fees?.currency || `NA`}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Card First Six'}</Label>
                    <Label className="text-muted">
                      {details?.payment?.card?.first_six || `NA`}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Card Last Four'}</Label>
                    <Label className="text-muted">
                      {details?.payment?.card?.last_four || `NA`}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Card Type'}</Label>
                    <Label className="text-muted">
                      {details?.payment?.card?.type || `NA`}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Discount Final Amount'}</Label>
                    <Label className="text-muted">
                      {details?.payment?.discount?.final?.amount || `NA`}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">
                      {'Discount Final Currency'}
                    </Label>
                    <Label className="text-muted">
                      {details?.payment?.discount?.final?.currency || `NA`}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">
                      {'Discount Final Percentage'}
                    </Label>
                    <Label className="text-muted">
                      {details?.payment?.discount?.final?.percentage || `NA`}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">
                      {'Green Fees Final Amount'}
                    </Label>
                    <Label className="text-muted">
                      {details?.payment?.green_fees?.final?.amount || `NA`}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">
                      {'Green Fees Final Currency'}
                    </Label>
                    <Label className="text-muted">
                      {details?.payment?.green_fees?.final?.currency || `NA`}
                    </Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Remarks</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Customer'}</Label>
                    <Label className="text-muted">
                      {details?.remarks?.customer || `NA`}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Admin Cancellation'}</Label>
                    <Label className="text-muted">
                      {details?.remarks?.admin_cancellation || `NA`}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Admin Confirmed'}</Label>
                    <Label className="text-muted">
                      {details?.remarks?.admin_confirmed || `NA`}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Admin Recommended'}</Label>
                    <Label className="text-muted">
                      {details?.remarks?.admin_recommended || `NA`}
                    </Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default BookingDetails
