import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Input, Modal, ModalBody, ModalHeader } from 'reactstrap'

import { UPDATE_GOLF_BOOKING } from '../../../../store/application/actionType'

const UpdateModal = ({ data, onCloseHandler }) => {
  const dispatch = useDispatch()

  const [modal_varying1, setmodal_varying1] = useState(true)

  const [modalData, setModalData] = useState(null)

  useEffect(() => {
    if (data !== null) {
      setModalData(data)
    }
  }, [data])

  const bookingUpdateHanlder = () => {
    onCloseHandler(false)
    setmodal_varying1(!modal_varying1)

    // call an api with update details

    dispatch({
      type: UPDATE_GOLF_BOOKING,
      payload: { test: 123 },
    })
  }

  const tog_varying1 = () => {
    onCloseHandler(false)
    setmodal_varying1(!modal_varying1)
  }

  return (
    <Modal
      isOpen={modal_varying1}
      toggle={() => {
        tog_varying1()
      }}
      id="exampleModal"
    >
      <ModalHeader
        toggle={() => {
          tog_varying1()
        }}
      >
        Booking Update
      </ModalHeader>
      <ModalBody>
        <form>
          <div className="mb-3">
            <label htmlFor="customer-name" className="col-form-label">
              Confirmed GC
            </label>
            <select
              className="form-select"
              name="projectName"
              // value={filter.projectName}
              // onChange={inputChangeHandler}
            >
              <option value="">Choose...</option>
              <option value="Pending">Pending</option>
              <option value="Process">Process</option>
              <option value="Confirmed">Confirmed</option>
              <option value="Cancelled">Cancelled</option>
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="message-text" className="col-form-label">
              Confirmed DOP
            </label>
            <Input
              type="date"
              className="form-control"
              id="customer-name"
              value=""
            />
          </div>
          <div className="mb-3">
            <label htmlFor="message-text" className="col-form-label">
              Confirmed Tee Time
            </label>
            <Input
              type="time"
              className="form-control"
              id="customer-name"
              value=""
            />
          </div>
          <div className="mb-3">
            <label htmlFor="message-text" className="col-form-label">
              Confirmed Number
            </label>
            <Input
              type="number"
              className="form-control"
              id="customer-name"
              value=""
            />
          </div>
        </form>
      </ModalBody>
      <div className="modal-footer d-flex justify-content-center">
        <Button color="primary" onClick={bookingUpdateHanlder}>
          Submit
        </Button>
      </div>
    </Modal>
  )
}

export default UpdateModal
