import {
  DOWNLOAD_DUMP,
  DOWNLOAD_DUMP_SUCCESS,
  DOWNLOAD_DUMP_ERROR,
} from './../../actionType.js'

const initialState = {
  data: null,
  message: null,
  loading: false,
  success: false,
  error: false,
}

const DownloadDump = (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOAD_DUMP:
      state = {
        ...state,
        data: null,
        loading: true,
        success: false,
        error: false,
        message: null,
      }
      break

    case DOWNLOAD_DUMP_SUCCESS:
      state = {
        ...state,
        data: action.payload.results,
        loading: false,
        success: true,
        error: false,
        message: null,
      }
      break

    case DOWNLOAD_DUMP_ERROR:
      state = {
        ...state,
        data: null,
        loading: false,
        success: false,
        error: true,
        message: action.payload,
      }
      break

    default:
      state = { ...state }
      break
  }
  return state
}

export default DownloadDump
