import React from 'react'
import { Grid, _ } from 'gridjs-react'
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'

const data = [
  [
    '01',
    'Jonathan',
    'jonathan@example.com',
    'Senior Implementation Architect',
    'Hauck Inc',
    'Holy See',
  ],
  [
    '02',
    'Harold',
    'harold@example.com',
    'Forward Creative Coordinator',
    'Metz Inc',
    'Iran',
  ],
]

const Table = (props) => {
  return (
    <React.Fragment>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader>
                <h4 className="card-title mb-0 flex-grow-1">
                  {props.headingPreview}
                </h4>
              </CardHeader>

              <CardBody>
                <div id="table-gridjs">
                  <Grid
                    data={props.data}
                    columns={[
                      {
                        name: 'ID',
                        formatter: (cell) =>
                          _(<span className="fw-semibold">{cell}</span>),
                      },

                      {
                        name: 'Name',
                        formatter: (cell) =>
                          _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Email',
                        formatter: (cell) =>
                          _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Position',
                        formatter: (cell) =>
                          _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Company',
                        formatter: (cell) =>
                          _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Country',
                        formatter: (cell) =>
                          _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Actions',
                        width: '120px',
                        formatter: (cell) =>
                          _(
                            <span
                              className="btn btn-sm btn-success"
                              onClick={props.editForm}
                            >
                              Details
                            </span>,
                          ),
                      },
                    ]}
                    search={true}
                    sort={true}
                    pagination={{ enabled: true, limit: 5 }}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default Table
