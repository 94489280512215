/* ---------------------------------- COMMON ---------------------------------- */
// LOGIN
export const LOGIN = `LOGIN`
export const LOGIN_SUCCESS = `LOGIN_SUCCESS`
export const LOGIN_ERROR = `LOGIN_ERROR`
export const LOGIN_EMPTY = `LOGIN_EMPTY`

// LOGOUT
export const LOGOUT = `LOGOUT`
export const LOGOUT_SUCCESS = `LOGOUT_SUCCESS`
export const LOGOUT_ERROR = `LOGOUT_ERROR`

// DUMP
export const DOWNLOAD_DUMP = `DOWNLOAD_DUMP`
export const DOWNLOAD_DUMP_SUCCESS = `DOWNLOAD_DUMP_SUCCESS`
export const DOWNLOAD_DUMP_ERROR = `DOWNLOAD_DUMP_ERROR`

// MAILS
export const SEND_MAIL = `SEND_MAIL`
export const SEND_MAIL_SUCCESS = `SEND_MAIL_SUCCESS`
export const SEND_MAIL_ERROR = `SEND_MAIL_ERROR`

// GOLF COURSES
export const GET_GOLF_COURSES = `GET_GOLF_COURSES`
export const GET_GOLF_COURSES_SUCCESS = `GET_GOLF_COURSES_SUCCESS`
export const GET_GOLF_COURSES_ERROR = `GET_GOLF_COURSES_ERROR`

// PROJECTS
export const GET_PROJECTS = `GET_PROJECTS`
export const GET_PROJECTS_SUCCESS = `GET_PROJECTS_SUCCESS`
export const GET_PROJECTS_ERROR = `GET_PROJECTS_ERROR`

/* ---------------------------------- FEATURES ---------------------------------- */
/* ----------------- GOLF BOOKING ----------------- */

// GET GOLF BOOKINGS
export const GET_GOLF_BOOKING = `GET_GOLF_BOOKING`
export const GET_GOLF_BOOKING_SUCCESS = `GET_GOLF_BOOKING_SUCCESS`
export const GET_GOLF_BOOKING_ERROR = `GET_GOLF_BOOKING_ERROR`

// GET GOLF BOOKING BY BOOKING ID
export const GET_GOLF_BOOKING_BY_ID = `GET_GOLF_BOOKING_BY_ID`
export const GET_GOLF_BOOKING_BY_ID_SUCCESS = `GET_GOLF_BOOKING_BY_ID_SUCCESS`
export const GET_GOLF_BOOKING_BY_ID_ERROR = `GET_GOLF_BOOKING_BY_ID_ERROR`

// UPDATE GOLF BOOKING
export const UPDATE_GOLF_BOOKING = `UPDATE_GOLF_BOOKING`
export const UPDATE_GOLF_BOOKING_SUCCESS = `UPDATE_GOLF_BOOKING_SUCCESS`
export const UPDATE_GOLF_BOOKING_ERROR = `UPDATE_GOLF_BOOKING_ERROR`
export const EMPTY_UPDATE_GOLF_BOOKING = `EMPTY_UPDATE_GOLF_BOOKING`
