import './Paginate.scss'

const Paginate = ({ onclick, prevDisabled, nextDisabled }) => {
  return (
    <div className="gridjs-footer d-flex justify-content-end">
      <div className="gridjs-pagination">
        <div className="gridjs-pages">
          <button
            className=""
            disabled={prevDisabled}
            onClick={() => onclick('PREVIOUS')}
          >
            Previous
          </button>
          {/* <button className="gridjs-currentPage">1</button> */}
          <button
            className=""
            disabled={nextDisabled}
            onClick={() => onclick('NEXT')}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  )
}

export default Paginate
