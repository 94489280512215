import React from 'react'

import { Container } from 'reactstrap'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { BreadCrumb, Loader, Paginate } from '../../Common/index'
import { FilterForm, Table, Details } from './Components/index'

const Fab = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = (title) => {
    if (title === `Dashboard`) {
      history.push('/dashboard')
    } else if (title === `Fab Booking`) {
      history.push('/fab')
      //   setIsShowBookingDetails(false)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="position-relative">
          <BreadCrumb
            title="Fab Booking"
            navigationby={breadcrumNavigationHandler}
            navigation={['Dashboard', 'Fab Booking']}
          />
          <FilterForm />
          {/* <Table
            headingPreview={'Booking Data'}
            // data={golfBookingData}
            // onClickHandler={tableButtonClickHandler}
          >
            <Paginate
            // onclick={paginationHandler}
            // prevDisabled={currentPageNumber === 1}
            // nextDisabled={golfBookingData?.length < 10}
            />
          </Table> */}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Fab
