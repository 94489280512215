/* ---------------------------- PRODUCTION ---------------------------- */

// // API base URL
export const baseURL = `https://prod-mastercard-api.thriwe.com`

// Common headers
export const commonHeaders = {
  'x-parse-application-id': 'PROD_APPLICATION_ID',
  'x-parse-master-key': 'PROD_MASTER_KEY',
  'Content-Type': 'application/json',
  // 'Accept-Encoding': 'gzip, deflate, br',
  Accept: '*/*',
}

/* ---------------------------- STAGING ---------------------------- */
// API base URL
// export const baseURL = `https://stag-mastercard-api.thriwe.com`

// // Common headers
// export const commonHeaders = {
//   'x-parse-application-id': 'STAG_APPLICATION_ID',
//   'x-parse-master-key': 'STAG_MASTER_KEY',
//   'Content-Type': 'application/json',
//   // 'Accept-Encoding': 'gzip, deflate, br',
//   Accept: '*/*',
// }
