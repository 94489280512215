import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'
import { LOGOUT, LOGIN_EMPTY } from '../../store/application/actionType'

//import images
import avatar1 from './../../assets/custom/avatar-1.jpg'

const ProfileDropdown = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { userLogout, userLogoutLoading, userLogoutError } = useSelector(
    (state) => ({
      userLogout: state.UserLogout.data,
      userLogoutLoading: state.UserLogout.loading,
      userLogoutError: state.UserLogout.error,
    }),
  )

  // const { user } = useSelector((state) => ({
  //   user: state.Profile.user,
  // }))

  const [userName, setUserName] = useState('Thriwe')

  //Dropdown Toggle
  const [isProfileDropdown, setIsProfileDropdown] = useState(false)
  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown)
  }

  const logoutHandler = () => {
    dispatch({
      type: LOGOUT,
      // payload: { test: 123 },
    })
  }

  useEffect(() => {
    if (userLogout !== null) {
      dispatch({
        type: LOGIN_EMPTY,
      })
      sessionStorage.clear()
      history.push(`/login`)
      window.location.reload()
    }
  }, [userLogout])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={isProfileDropdown}
        toggle={toggleProfileDropdown}
        className="ms-sm-3 header-item topbar-user"
      >
        <DropdownToggle tag="button" type="button" className="btn">
          <span className="d-flex align-items-center">
            <img
              className="rounded-circle header-profile-user"
              src={avatar1}
              alt="Header Avatar"
            />
            <span className="text-start ms-xl-2">
              <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                {JSON.parse(sessionStorage.getItem('authUser')).data.firstname}
              </span>
              {/* <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">
                Founder
              </span> */}
            </span>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {/*<h6 className="dropdown-header">Welcome {userName}!</h6>
           <DropdownItem href="/profile">
            <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">Profile</span>
          </DropdownItem>
          <DropdownItem href="/apps-chat">
            <i className="mdi mdi-message-text-outline text-muted fs-16 align-middle me-1"></i>{' '}
            <span className="align-middle">Messages</span>
          </DropdownItem>
          <DropdownItem href="/apps-tasks-kanban">
            <i className="mdi mdi-calendar-check-outline text-muted fs-16 align-middle me-1"></i>{' '}
            <span className="align-middle">Taskboard</span>
          </DropdownItem>
          <DropdownItem href="/pages-faqs">
            <i className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i>{' '}
            <span className="align-middle">Help</span>
          </DropdownItem>
          <div className="dropdown-divider"></div>
          <DropdownItem href="/pages-profile">
            <i className="mdi mdi-wallet text-muted fs-16 align-middle me-1"></i>{' '}
            <span className="align-middle">
              Balance : <b>$5971.67</b>
            </span>
          </DropdownItem>
          <DropdownItem href="/pages-profile-settings">
            <span className="badge bg-soft-success text-success mt-1 float-end">
              New
            </span>
            <i className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i>{' '}
            <span className="align-middle">Settings</span>
          </DropdownItem>
          <DropdownItem href="/auth-lockscreen-basic">
            <i className="mdi mdi-lock text-muted fs-16 align-middle me-1"></i>{' '}
            <span className="align-middle">Lock screen</span>
          </DropdownItem> */}
          <DropdownItem onClick={logoutHandler}>
            <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{' '}
            <span className="align-middle" data-key="t-logout">
              Logout
            </span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default ProfileDropdown
