import React, { useEffect, useState } from 'react'

import { Card, CardBody, Col, Container, Form, Label, Row } from 'reactstrap'
import { PreviewCardHeader } from '../../../Common'

const FilterForm = () => {
  return (
    <React.Fragment>
      <Container fluid>
        <Row>
          <Col xxl={12}>
            <Card>
              <PreviewCardHeader title="Filter" />
              <CardBody>
                <div className="live-preview">
                  <Form>
                    {/* <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="ForminputState" className="form-label">
                          Projects
                        </Label>
                        <select
                          className="form-select"
                          name="projectName"
                          // value={filter.projectName}
                          // onChange={inputChangeHandler}
                        >
                          <option value="">Choose...</option>
                          {[{ project_name: 'testing', objectId: 123 }]?.map(
                            (project, index) => (
                              <option value={project?.objectId} key={index}>
                                {project?.project_name}
                              </option>
                            ),
                          )}
                        </select>
                      </div>
                    </Col> */}
                  </Form>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default FilterForm
