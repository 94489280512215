import React, { useEffect, useState } from 'react'

import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
  Alert,
} from 'reactstrap'
import ParticlesAuth from './../../../pages/AuthenticationInner/ParticlesAuth'

//redux
import { useSelector, useDispatch } from 'react-redux'
import cogoToast from 'cogo-toast'

import { Redirect, withRouter, Link, useHistory } from 'react-router-dom'

import ThriweWhite from './../../../assets/custom/thriwe-white-logo.png'
import { toast } from 'react-toastify'

import { validateEmail } from '../../Helpers/Helpers'
import { LOGIN } from '../../../store/application/actionType'
import { Loader } from '../../Common'

const Login = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { user } = useSelector((state) => ({
    user: state.Account.user,
  }))

  const { error } = useSelector((state) => ({
    error: state.Login.error,
  }))

  const [isLoading, setIsLoading] = useState(false)

  /* -------------------- Custom Code -------------------- */

  const {
    userLogin,
    userLoginLoading,
    userLoginError,
    userLoginErrorMsg,
  } = useSelector((state) => ({
    userLogin: state.UserLogin.data,
    userLoginLoading: state.UserLogin.loading,
    userLoginError: state.UserLogin.error,
    userLoginErrorMsg: state.UserLogin.message,
  }))

  useEffect(() => {
    if (userLoginError) {
      cogoToast.error(`${userLoginErrorMsg}`)
    }
  }, [userLoginError])

  useEffect(() => {
    if (userLoginLoading) {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }, [userLoginLoading])

  useEffect(() => {
    if (userLogin?.code === 101) {
      toast.error(`Invalid username/password.`, {
        position: toast.POSITION.TOP_CENTER,
      })
    } else if (userLogin !== null) {
      sessionStorage.setItem(
        'authUser',
        JSON.stringify({
          status: 'success',
          token: userLogin?.sessionToken,
          data: userLogin,
        }),
        // <Redirect to="/dashboard" />,
        history.push(`/dashboard`),
      )
    }
  }, [userLogin])

  const initialState = {
    username: '',
    password: '',
  }
  const [state, setState] = useState(initialState)
  const [loading, setLoading] = useState(false)

  const handleInputChange = (e) => {
    const { name, value } = e.target

    switch (name) {
      case 'username':
        setState({ ...state, username: value })
        break

      case 'password':
        setState({ ...state, password: value })

      default:
      // do nothing
    }
  }

  const handleFormSubmit = (e) => {
    e.preventDefault()

    if (state.username === ``) {
      toast.error(`Email cannot be blank!`, {
        position: toast.POSITION.TOP_CENTER,
      })
    } else if (!validateEmail(state.username)) {
      toast.error(`Enter proper email`, {
        position: toast.POSITION.TOP_CENTER,
      })
    } else if (state.password === ``) {
      toast.error(`Password cannot be blank!`, {
        position: toast.POSITION.TOP_CENTER,
      })
    } else {
      // Call an api
      dispatch({
        type: LOGIN,
        payload: { ...state },
      })
    }
  }

  return (
    <React.Fragment>
      <ParticlesAuth>
        {false && <Loader />}
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <Link to="/login" className="d-inline-block auth-logo">
                      <img src={ThriweWhite} alt="" height="50" />
                    </Link>
                  </div>
                  {/* <p className="mt-3 fs-15 fw-medium">
                    Premium Admin & Dashboard Template
                  </p> */}
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">Welcome Back !</h5>
                      <p className="text-muted">
                        Sign in to continue to Thriwe.
                      </p>
                    </div>
                    {error && error ? (
                      <Alert color="danger"> {error} </Alert>
                    ) : null}
                    <div className="p-2 mt-4">
                      <Form onSubmit={handleFormSubmit}>
                        <div className="mb-3">
                          <Label htmlFor="email" className="form-label">
                            Email
                          </Label>
                          <Input
                            name="username"
                            className="form-control"
                            placeholder="Enter email"
                            type="text"
                            onChange={handleInputChange}
                            value={state.username}
                            // onBlur={validation.handleBlur}
                            // value={validation.values.email || ''}
                            // invalid={
                            //   validation.touched.email &&
                            //   validation.errors.email
                            //     ? true
                            //     : false
                            // }
                          />
                          {/* {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null} */}
                        </div>

                        <div className="mb-3">
                          {/* <div className="float-end">
                            <Link to="/forgot-password" className="text-muted">
                              Forgot password?
                            </Link>
                          </div> */}
                          <Label
                            className="form-label"
                            htmlFor="password-input"
                          >
                            Password
                          </Label>
                          <div className="position-relative auth-pass-inputgroup mb-3">
                            <Input
                              name="password"
                              type="password"
                              className="form-control pe-5"
                              placeholder="Enter Password"
                              onChange={handleInputChange}
                              value={state.password}
                              // onBlur={validation.handleBlur}
                              // invalid={
                              //   validation.touched.password &&
                              //   validation.errors.password
                              //     ? true
                              //     : false
                              // }
                            />
                            {/* {validation.touched.password &&
                            validation.errors.password ? (
                              <FormFeedback type="invalid">
                                {validation.errors.password}
                              </FormFeedback>
                            ) : null} */}
                            <button
                              className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                              type="button"
                              id="password-addon"
                            >
                              {/* <i className="ri-eye-fill align-middle"></i> */}
                            </button>
                          </div>
                        </div>

                        {/* <div className="form-check">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="auth-remember-check"
                          />
                          <Label
                            className="form-check-label"
                            htmlFor="auth-remember-check"
                          >
                            Remember me
                          </Label>
                        </div> */}

                        <div className="mt-4">
                          <Button
                            color="success"
                            className="btn btn-success w-100"
                            type="submit"
                          >
                            Sign In
                          </Button>
                        </div>
                        {/* <button onClick={() => console.log({ state })}>
                          get State
                        </button> */}

                        {/* <div className="mt-4 text-center">
                          <div className="signin-other-title">
                            <h5 className="fs-13 mb-4 title">Sign In with</h5>
                          </div>
                          <div>
                            <FacebookLogin
                              appId={facebook.APP_ID}
                              autoLoad={false}
                              callback={facebookResponse}
                              render={(renderProps) => (
                                <Button
                                  color="primary"
                                  className="btn-icon me-1"
                                  onClick={renderProps.onClick}
                                >
                                  <i className="ri-facebook-fill fs-16" />
                                </Button>
                              )}
                            />
                            <GoogleLogin
                              clientId={
                                google.CLIENT_ID ? google.CLIENT_ID : ''
                              }
                              render={(renderProps) => (
                                <Button
                                  color="danger"
                                  to="#"
                                  className="btn-icon me-1"
                                  onClick={renderProps.onClick}
                                >
                                  <i className="ri-google-fill fs-16" />
                                </Button>
                              )}
                              onSuccess={googleResponse}
                              onFailure={() => {}}
                            />
                            <Button color="dark" className="btn-icon">
                              <i className="ri-github-fill fs-16"></i>
                            </Button>{' '}
                            <Button color="info" className="btn-icon">
                              <i className="ri-twitter-fill fs-16"></i>
                            </Button>
                          </div>
                        </div> */}
                      </Form>
                    </div>
                  </CardBody>
                </Card>

                {/* <div className="mt-4 text-center">
                  <p className="mb-0">
                    Don't have an account ?{' '}
                    <Link
                      to="/register"
                      className="fw-semibold text-primary text-decoration-underline"
                    >
                      {' '}
                      Signup{' '}
                    </Link>{' '}
                  </p>
                </div> */}
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  )
}

export default withRouter(Login)
