import React from 'react'
import { Link } from 'react-router-dom'
import { Col, Row } from 'reactstrap'

const BreadCrumb = (props) => {
  return (
    <React.Fragment>
      <Row>
        <Col xs={12}>
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="mb-sm-0">{props.title}</h4>

            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                {props?.navigation?.map((data, index) => {
                  return (
                    <li key={index} className="breadcrumb-item">
                      <span
                        style={{ cursor: 'pointer' }}
                        onClick={() => props.navigationby(`${data}`)}
                      >
                        {data}
                      </span>
                    </li>
                  )
                })}
              </ol>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default BreadCrumb
