import axios from 'axios'

export const post_req = async (endPoint, data, header) => {
  return axios({
    method: 'POST',
    url: endPoint,
    data: data,
    headers: header,
  })
    .then(function (response) {
      return response
    })
    .catch(function (err) {
      return err
    })
}

export const get_req = async (endPoint, header) => {
  return axios({
    method: 'get',
    url: endPoint,
    headers: header,
  })
    .then(function (response) {
      return response
    })
    .catch(function (err) {
      return err
    })
}

export const validateEmail = (email) => {
  var re = /\S+@\S+\.\S+/
  return re.test(email)
}
