import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import {
  Col,
  Label,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Input,
} from 'reactstrap'

import { DOWNLOAD_DUMP } from '../../../../store/application/actionType'
import { Loader } from '../../../Common'

const DumpModal = ({ data, onCloseHandler }) => {
  const dispatch = useDispatch()

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialObj = {
    fromDate: '',
    toDate: '',
  }
  const [state, setState] = useState(initialObj)

  const [modal_varying1, setmodal_varying1] = useState(true)
  const [modalData, setModalData] = useState(null)

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = (e) => {
    const { name, value } = e.target

    switch (name) {
      case 'fromDate':
        setState({ ...state, fromDate: value })
        break

      case 'toDate':
        setState({ ...state, toDate: value })
        break

      default:
      // do nothing
    }
  }

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    onCloseHandler(false)
    setmodal_varying1(!modal_varying1)

    dispatch({
      type: DOWNLOAD_DUMP,
    })
  }

  const tog_varying1 = () => {
    onCloseHandler(false)
    setmodal_varying1(!modal_varying1)
  }

  return (
    <React.Fragment>
      {/* <Loader /> */}
      <Modal
        isOpen={modal_varying1}
        toggle={() => {
          tog_varying1()
        }}
      >
        <ModalHeader
          toggle={() => {
            tog_varying1()
          }}
        >
          Report Manager
        </ModalHeader>
        <ModalBody>
          <form>
            <div className="mb-3">
              <Col md={12}>
                <div className="mb-3">
                  <Label className="form-label">From Date (DD/MM/YYYY)</Label>
                  <Input
                    type="date"
                    className="form-control"
                    name="fromDate"
                    value={state.fromDate}
                    onChange={inputChangeHandler}
                  />
                </div>
              </Col>

              <Col md={12}>
                <div className="mb-3">
                  <Label className="form-label">To Date (DD/MM/YYYY)</Label>
                  <Input
                    type="date"
                    className="form-control"
                    name="toDate"
                    value={state.toDate}
                    onChange={inputChangeHandler}
                  />
                </div>
              </Col>
            </div>
          </form>
        </ModalBody>
        <div className="modal-footer d-flex justify-content-center">
          <Button
            className="overlay-disable"
            color="primary"
            onClick={submitHandler}
            disabled={state.fromDate === '' || state.toDate === ''}
          >
            Check Report
          </Button>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default DumpModal
