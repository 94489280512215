import React from 'react'
import { Redirect } from 'react-router-dom'

import {
  Dashboard,
  GolfBooking,
  Login,
  Home,
  Fab,
} from './../Containers/Pages/index'

// import Login from '../pages/Authentication/Login'
import Logout from '../pages/Authentication/Logout'

const publicRoutes = [
  { path: '/login', component: Login },
  { path: '/logout', component: Logout },
  // { path: '/', component: Home },
]

const authProtectedRoutes = [
  { path: '/dashboard', component: Dashboard },
  { path: '/golf-booking', component: GolfBooking },
  { path: '/fab', component: Fab },
  { path: '/', component: Home },
  // { path: '*', component: },
]

export { authProtectedRoutes, publicRoutes }
