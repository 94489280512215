import React from 'react'

import { Grid, _ } from 'gridjs-react'
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'

const Table = (props) => {
  return (
    <>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader>
                <h4 className="card-title mb-0 flex-grow-1">
                  {props.headingPreview}
                </h4>
              </CardHeader>

              <CardBody>
                <div id="table-gridjs">
                  <Grid
                    data={props.data}
                    columns={[
                      // {
                      //   name: 'Booking id',
                      //   width: '120px',
                      //   formatter: (cell, row) =>
                      //     _(
                      //       <input
                      //         className="form-check-input"
                      //         type="checkbox"
                      //         name="checkAll"
                      //         value="option6"
                      //       />,
                      //     ),
                      // },

                      {
                        name: 'Booking id',
                        width: '120px',
                        formatter: (cell, row) =>
                          _(
                            <span
                              className="fw-semibold"
                              style={{
                                cursor: 'pointer',
                                // textDecoration: 'underline',
                              }}
                              onClick={() =>
                                props.onClickHandler({
                                  id: `${row.cells[0].data}`,
                                  status: `VIEW`,
                                })
                              }
                            >
                              {cell}
                            </span>,
                          ),
                      },

                      {
                        name: 'Order id',
                        width: '170px',
                        formatter: (cell) =>
                          _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Payment id',
                        width: '120px',
                        formatter: (cell) =>
                          _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'First Name',
                        width: '120px',
                        formatter: (cell) =>
                          _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Last Name',
                        width: '150px',
                        formatter: (cell) =>
                          _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Phone',
                        width: '150px',
                        formatter: (cell) =>
                          _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Email',
                        width: '120px',
                        formatter: (cell) =>
                          _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Golf Course Name',
                        width: '120px',
                        formatter: (cell) =>
                          _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Created At',
                        width: '120px',
                        formatter: (cell) =>
                          _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Updated At',
                        width: '120px',
                        formatter: (cell) =>
                          _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Date of Play',
                        width: '120px',
                        formatter: (cell) =>
                          _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Time of Play',
                        width: '120px',
                        formatter: (cell) =>
                          _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Golf Address',
                        width: '120px',
                        formatter: (cell) =>
                          _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Golf Country',
                        width: '120px',
                        formatter: (cell) =>
                          _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Golf Region',
                        width: '120px',
                        formatter: (cell) =>
                          _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Status',
                        width: '120px',
                        formatter: (cell) =>
                          _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Actions',
                        width: '120px',
                        formatter: (cell, row) =>
                          _(
                            <div className="d-flex justify-content-center flex-column">
                              {row.cells[15].data === 'PENDING' && (
                                <>
                                  <span
                                    className="btn btn-sm btn-primary mb-1"
                                    onClick={() =>
                                      props.onClickHandler({
                                        id: `${row.cells[0].data}`,
                                        status: `PROCESSED`,
                                      })
                                    }
                                  >
                                    Process
                                  </span>

                                  <span
                                    className="btn btn-sm btn-primary mb-1"
                                    onClick={() =>
                                      props.onClickHandler({
                                        id: `${row.cells[0].data}`,
                                        status: `CANCELLED`,
                                      })
                                    }
                                  >
                                    Cancel
                                  </span>
                                </>
                              )}

                              {row.cells[15].data === 'PROCESSED' && (
                                <>
                                  <span
                                    className="btn btn-sm btn-primary mb-1"
                                    onClick={() =>
                                      props.onClickHandler({
                                        id: `${row.cells[0].data}`,
                                        status: `CONFIRMED`,
                                      })
                                    }
                                  >
                                    Confirmed
                                  </span>

                                  <span
                                    className="btn btn-sm btn-primary mb-1"
                                    onClick={() =>
                                      props.onClickHandler({
                                        id: `${row.cells[0].data}`,
                                        status: `CANCELLED`,
                                      })
                                    }
                                  >
                                    Cancel
                                  </span>
                                </>
                              )}

                              {row.cells[15].data === 'CONFIRMED' && (
                                <>
                                  <span
                                    className="btn btn-sm btn-primary mb-1"
                                    onClick={() =>
                                      props.onClickHandler({
                                        id: `${row.cells[0].data}`,
                                        status: `CANCELLED`,
                                      })
                                    }
                                  >
                                    Cancel
                                  </span>
                                </>
                              )}
                            </div>,
                          ),
                      },
                    ]}
                    search={false}
                    sort={false}
                    fixedHeader={true}
                    height="400px"
                    pagination={{ enabled: false, limit: 5 }}
                  />
                </div>
                {props.children}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Table
