import { takeEvery, fork, put, all, call } from 'redux-saga/effects'

import fetch from './../../fetch'
import { baseURL, commonHeaders } from '../../../../Containers/Confiq/index.js'
import {
  SEND_MAIL,
  SEND_MAIL_SUCCESS,
  SEND_MAIL_ERROR,
} from './../../actionType.js'

function* sendMail(payload) {
  try {
    const response = yield call(fetch, {
      url: `${baseURL}/send-mail`,
      method: 'POST',
      data: payload?.payload?.payloadData,
      headers: {
        // ...commonHeaders,
        'x-api-key': '5b6b776f2bd64fa9b5822c901b8b62c1',
        'content-type': 'application/json',
      },
    })

    if (response?.error === undefined) {
      yield put({ type: SEND_MAIL_SUCCESS, payload: response })
    } else {
      yield put({ type: SEND_MAIL_ERROR, payload: response.error })
    }
  } catch (error) {
    yield put({ type: SEND_MAIL_ERROR, payload: error })
  }
}
export function* WatchSendMail() {
  yield takeEvery(SEND_MAIL, sendMail)
}

function* sendMailSaga() {
  yield all([fork(WatchSendMail)])
}

export default sendMailSaga
