export default async function fetchBooking(config) {
  const response = await fetch(`${config.url}`, {
    method: `${config.method}`,
    headers: {
      ...config.headers,
    },
    body: JSON.stringify(config.data),
  })
    .then(function (response) {
      return response
    })
    .catch(function (err) {
      return err
    })
  return response.json()
}
